import React from "react";
import { Link } from "react-router-dom";
import "./About.css";

const About = () => {
	return (
		<section className="about" id="about">
			<div className="about__container">
				<div className="about__typography">
					<span>about me</span>
					<span>about me</span>
					<span>about me</span>
				</div>
				<div className="about__text">
					<span className="about__description">
						Hello! I am Bayu Nugraha, Passionate in
						<span className="about__outline"> Digital Marketing</span> mainly in{" "}
						<span className="about__outline">Website Development</span> and {" "}
						<span className="about__outline">SEO optimization</span> aspects.
					</span>
					<span className="about__button">
						<Link to="/me">
							Download My Resume <i className="uil uil-arrow-up-right"></i>
						</Link>
					</span>
				</div>
			</div>
		</section>
	);
};

export default About;
