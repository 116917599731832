import React from "react";
import { motion, useScroll, useTransform } from "framer-motion";

import picture from "../../assets/profilePicture.jpeg";
import resume from "../../assets/bayyngrh-resume.pdf"

import "./moreInfo.css";
import { Link } from "react-router-dom";

const MoreInfo = () => {
	const { scrollYProgress } = useScroll();
	const first = useTransform(scrollYProgress, [0, 1], [0, 600]);
	const second = useTransform(scrollYProgress, [0, 1], [0, 400]);
	return (
		<section className="info">
			<div className="back__button">
				<Link to="/">
					<i class="uil uil-angle-left-b"></i>
				</Link>
			</div>
			<div className="info__container">
				<div className="info__me">
					<div className="info__more">
						<img className="info__picture" src={picture} alt="me" />
						<span>
						Passionate Digital Marketing Specialist focused on social media management and e-commerce optimization. 
						Skilled in SEO, content creation, and analytics with proven strategies to boost brand visibility and engagement. 
						Collaborative and driven to deliver impactful marketing solutions that foster growth.
						</span>
					</div>
					<div className="info__sosmed">
					<span>Resume</span>
					<a href={resume} download>
						Download My Resume <i class="uil uil-arrow-up-right"></i>
					</a>
					</div>
					<div className="info__sosmed">
						<span>Social Media</span>
						<a
							target="_blank"
							rel="noreferrer noopener"
							href="https://wa.me/6285157778195/?text=Hello%20Bayu"
						>
							WhatsApp <i class="uil uil-arrow-up-right"></i>
						</a>
						<a
							target="_blank"
							rel="noreferrer noopener"
							href="https://instagram.com/r.bayunugraha"
						>
							Instagram <i class="uil uil-arrow-up-right"></i>
						</a>
						<a target="_blank" rel="noreferrer noopener" href="https://www.linkedin.com/in/">
							LinkedIn <i class="uil uil-arrow-up-right"></i>
						</a>
					</div>
				</div>
				<div className="info__achieve">
					<div className="info__item">
						<h1>Certificate</h1>
						<div className="info__detail">
							<a
								target="_blank"
								rel="noreferrer noopener"
								href="https://www.dicoding.com/certificates/MRZMQV8OLPYQ"
							>
								Javascript Fundamental Dicoding <i class="uil uil-arrow-up-right"></i>
							</a>
							<a
								target="_blank"
								rel="noreferrer noopener"
								href="https://www.dicoding.com/certificates/L4PQGWM9VZO1"
							>
								Learn Web Development Dicoding <i class="uil uil-arrow-up-right"></i>
							</a>
						</div>
					</div>
					<div className="info__item">
						<h1>Experience</h1>
						<div className="info__detail">
							<a target="_blank" rel="noreferrer noopener" href="https://github.com/">
								Digital Marketing Freelancer - Shagida Bali Fashion Art <i class="uil uil-arrow-up-right"></i>
							</a>
						</div>
						<div className="info__detail">
							<a target="_blank" rel="noreferrer noopener" href="https://github.com/">
								Web Developer Freelancer - Divine Villa Seminyak <i class="uil uil-arrow-up-right"></i>
							</a>
						</div>
						<div className="info__detail">
							<a target="_blank" rel="noreferrer noopener" href="https://github.com/">
								IT Staff - PT. Halo Belanja halobelanja.com <i class="uil uil-arrow-up-right"></i>
							</a>
						</div>
						<div className="info__detail">
							<a target="_blank" rel="noreferrer noopener" href="https://github.com/">
								Admin Staff - Badan Narkotika Nasional Provinsi Bali <i class="uil uil-arrow-up-right"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="info__typography">
				<div class="typo__filled">
					<motion.h1
						initial={{ x: -1800 }}
						animate={{ x: 0, transition: { duration: 0.8 } }}
						style={{ x: first }}
						className="info__typo"
					>
						contact <span className="typo__outline">me</span> - contact{" "}
						<span className="typo__outline">me</span> - contact{" "}
						<span className="typo__outline">me</span> - contact{" "}
						<span className="typo__outline">me</span> - contact{" "}
						<span className="typo__outline">me</span> -
					</motion.h1>
				</div>
				<div class="typo__filled">
					<motion.h1
						initial={{ x: -1800 }}
						animate={{ x: 0, transition: { duration: 0.8 } }}
						style={{ x: second }}
						className="info__typo"
					>
						<span className="typo__outline"> contact</span> me -{" "}
						<span className="typo__outline"> contact</span> me -{" "}
						<span className="typo__outline"> contact</span> me -{" "}
						<span className="typo__outline"> contact</span> me -{" "}
						<span className="typo__outline"> contact</span> me -{" "}
						<span className="typo__outline"> contact</span> me -{" "}
						<span className="typo__outline"> contact</span> me -
					</motion.h1>
				</div>
			</div>
		</section>
	);
};

export default MoreInfo;
