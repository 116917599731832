import React from "react";
import work4 from "../../assets/mockup-akara.png";
import work3 from "../../assets/mockup-okas.png";
import work2 from "../../assets/mockup-aerialdoc.png";
import work1 from "../../assets/mockup-betterdays.png";
import "./work.css";

const Work = () => {
	return (
		<section className="work" id="work">
			<div className="work__title">
				<div className="work__typography">
					<h2>work</h2>
					<h2>work</h2>
					<h2>work</h2>
				</div>
				<span>Works & Projects</span>
			</div>
			<div className="work__container">
				<div className="work__menu">
						<div className="work__text">
							<span className="title__link">04.</span>
							<div className="work__subtitle">
								<span className="title__link">Divine Villas Bali</span>
								<p>Website</p>
							</div>
						</div>
					<div className="work__item">
						<img className="work__image" src={work4} alt="Divine Villas Bali" />
						<div className="work__button">
							<a
								target="_blank"
								rel="noreferrer noopener"
								href="https://divinevillasbali.com/"
							>
								<i class="uil uil-arrow-up-left"></i>
								<span>Online Preview</span>
							</a>
						</div>
					</div>
				</div>
				<div className="work__menu">
					<div className="work__text">
						<span className="title__link">03.</span>
						<div className="work__subtitle">
							<span className="title__link">Oka's Spa & Salon</span>
							<p>Website Offline</p>
						</div>
					</div>
					<div className="work__item">
						<img className="work__image" src={work3} alt="Oka's Span & Saloon" />
						<div className="work__button">
							<a>
								<i class="uil uil-arrow-up-left"></i>
								<span>Website Offline</span>
							</a>
						</div>
					</div>
				</div>
				<div className="work__menu">
					<div className="work__text">
						<span className="title__link">02.</span>
						<div className="work__subtitle">
							<span className="title__link">Aerial Doc</span>
							<p>Website</p>
						</div>
					</div>
					<div className="work__item">
						<img className="work__image" src={work2} alt="Aerial Doc" />
						<div className="work__button">
							<a target="_blank" rel="noreferrer noopener" href="https://aerialdoc.vercel.app/">
								<i class="uil uil-arrow-up-left"></i>
								<span>Online Preview</span>
							</a>
						</div>
					</div>
				</div>
				<div className="work__menu">
					<div className="work__text">
						<span className="title__link">01.</span>
						<div className="work__subtitle">
							<span className="title__link">Better Days Tattoo</span>
							<p>Website Offline</p>
						</div>
					</div>
					<div className="work__item">
						<img className="work__image" src={work1} alt="Better Days Tattoo" />
						<div className="work__button">
							<a>
								<i class="uil uil-arrow-up-left"></i>
								<span>Website Offline</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Work;
