import React from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import "./Hero.css";
// import profilePict from "../../assets/profilePicture.jpeg";

const Hero = () => {
	const { scrollYProgress } = useScroll();
	const right = useTransform(scrollYProgress, [0, 1], [0, 800]);
	const left = useTransform(scrollYProgress, [0, 1], [0, -800]);
	return (
		<section className="hero" id="hero">
			<div className="hero__container">
				<div className="text__filled">
					<motion.h1
						initial={{ x: -1800 }}
						animate={{
							x: 0,
							transition: {
								duration: 0.8,
								delay: 2,
							},
						}}
						style={{ x: right }}
						className="title"
					>
						website development
					</motion.h1>
				</div>
				<div className="text__outline">
					<motion.h1
						initial={{ x: -1800 }}
						animate={{
							x: 0,
							transition: {
								duration: 0.8,
								delay: 2,
							},
						}}
						style={{ x: right }}
						className="title"
					>
						website development
					</motion.h1>
				</div>
				<div className="text__filled to-left">
					<motion.h1
						initial={{ x: 1800 }}
						animate={{
							x: 0,
							transition: {
								duration: 0.8,
								delay: 2,
							},
						}}
						style={{ x: left }}
						className="title"
					>
						digital marketing
					</motion.h1>
				</div>
				<div className="text__outline to-left">
					<motion.h1
						initial={{ x: 1800 }}
						animate={{
							x: 0,
							transition: {
								duration: 0.8,
								delay: 2,
							},
						}}
						style={{ x: left }}
						className="title"
					>
						digital marketing
					</motion.h1>
				</div>
				<div className="hero__image-container">
					<motion.div
						initial={{ y: 200, opacity: 1 }}
						animate={{
							y: 0,
							opacity: 1,
							transition: { duration: 1, delay: 2.1 },
						}}
						className="hero__image"
					/>
				</div>
			</div>
		</section>
	);
};

export default Hero;
